<template>
  <div class="page-mdc-content">
    <NuxtLayout
      fallback="ocean-default"
      :frontmatter="ast?.data"
      :name="(`${config?.data?.theme || 'ocean'}-default` as LayoutKey)"
    >
      <MDCRenderer
        v-if="ast?.body"
        :key="parseMarkdownStatus"
        :body="ast.body"
        :data="ast.data"
      />
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
/**
 * This PageMdcContent component is responsible for rendering the Markdown content of a page.
 * It should be generic enough to be utilized both for the [...page_slug].vue dynamic pages, as well as
 * for the "required" portal-defined pages such as `/products`, so that the route is enforced, but the
 * user is free to customize the page content via markdown configuration.
 */
import { parseMarkdown } from '@nuxtjs/mdc/runtime'
import type { LayoutKey } from '#build/types/layouts'

const { config, info } = storeToRefs(usePortalStore())
const sessionStore = useSessionStore()
const { session } = storeToRefs(sessionStore)
const route = useRoute('page_slug')
const { t } = useI18n()
// Default page content for Portal required pages
const defaultPageContent = useState<string>(() => '')

const { enabled: previewModeEnabled, state: previewModeState } = usePortalPreviewMode()
// Determine if preview mode is enabled, and if the page exists in the preview data
const hasPreviewModePageData = computed((): boolean => previewModeEnabled.value && !!previewModeState.value.data.pages?.has(route.path))

// Important: must match the fetchKey in `layers/core/middleware/documents.ts` to ensure the cache is invalidated when the page is updated.
const fetchKey = computed((): string => `portal-page${previewModeEnabled.value ? `-preview-${previewModeState.value.preview_id}` : ''}${route.path === '/' ? '-home' : route.path.replace(/\//g, '-')}`)
const { data: cachedPageData } = useNuxtData(fetchKey.value)
const { transform, getCachedData } = serveCachedData()

// Flag to determine if the preview mode error should be shown. Default to `false`.
const shouldShowPreviewModeError = useState<boolean>(`show-preview-mode-error-${fetchKey.value}`, () => false)

const { data: pageData, error: pageError } = await usePortalApiDev('/api/v2/portal/pages/content', {
  query: {
    path: route.path, // query the page by path
  },
  // Reuse the same key to avoid re-fetching the document, e.g. `portal-page-about`
  key: fetchKey.value,
  onRequest: () => {
    // Always reset the preview mode error state when fetching new page data. No need to check if preview mode is enabled, as this will be handled in the onMounted hook.
    shouldShowPreviewModeError.value = false
  },
  ...(previewModeEnabled.value ?
    // If preview mode is enabled, bypass the cache
    { cache: 'no-store' } :
    // Otherwise, pick the desired properties and serve cached data if it exists and not expired
    {
      default: cachedPageData.value,
      pick: ['title', 'content', 'created_at', 'updated_at', 'public'], // You **must** include `content`; also referenced in `layers/core/middleware/documents.ts`
      transform,
      getCachedData,
    }
  ),
})

if (
  // If no preview page data
  !hasPreviewModePageData.value &&
  // Or no pageData or 404 from API, or if the user is not authenticated and the page is not public
  (!pageData.value || pageError.value?.statusCode === 404 || (!session.value.authenticated && !pageData.value?.public))
) {
  // If the portal is public, or if the user is authenticated, attempt to retrieve default page content
  if (info.value?.is_public === true || session.value.authenticated) {
    defaultPageContent.value = getDefaultPageContent(route.path)
  }

  if (!defaultPageContent.value) {
    // If the portal is not public and the user is not authenticated, and the homepage is private, redirect to /login
    if (info.value?.is_public === false && !session.value.authenticated && route.path === '/') {
      await navigateTo({ name: 'login-login_path' })
    } else {
      // Handle page not found error scenarios

      if (previewModeEnabled.value) {
        if (!hasPreviewModePageData.value) {
          // If in preview mode, set a flag that a 404 may need to be shown.
          shouldShowPreviewModeError.value = true

          // Defer showing the error until the onMounted hook to wait to see if the preview data is received.
        }
      } else {
        // Throw Page not found error
        throw createError({
          statusCode: 404,
          statusMessage: t('errors.pages.not_found'),
          fatal: true,
        })
      }
    }
  }
} else if (!pageData.value?.content && !hasPreviewModePageData.value) {
  // Page has no content
  throw createError({
    statusCode: 204,
    statusMessage: t('errors.pages.no_content'),
    fatal: true,
  })
} else if (pageError.value && !hasPreviewModePageData.value) {
  // Other page error
  throw createError({
    statusCode: pageError.value?.statusCode || 500,
    statusMessage: parseApiError(pageError.value as any) || t('errors.pages.unknown'),
    fatal: true,
  })
}

// Important: The page was retrieved and is viewable by the user, so clear any stored login return path cookie
if (sessionStore.getLoginReturnPath()) {
  sessionStore.setLoginReturnPath(null)
}

const emptyPageContent = '<span class="empty-page" />'
const contentToParse = computed((): string => {
  let content = ''

  if (hasPreviewModePageData.value) {
    // The `hasPreviewModePageData` computed variable ensures the page exists in the preview data
    content = previewModeState.value.data.pages!.get(route.path) || emptyPageContent
  } else {
    // Otherwise, use the page content from the API or default content
    content = pageData.value?.content || defaultPageContent.value || emptyPageContent
  }

  // If the content is empty, inject an empty span to prevent crashing the MDCRenderer component
  return content.endsWith('---') ? content + emptyPageContent : content
})

// Parse the previewMode content, or the raw markdown content, and return the result
const { data: ast, status: parseMarkdownStatus, refresh: parseMarkdownContent } = await useAsyncData(`parsed-markdown-${fetchKey.value}`, () => parseMarkdown(contentToParse.value, {
  toc: {
    depth: 2,
    searchDepth: 2,
  },
}))

watch([ast, hasPreviewModePageData], async ([newAst, hasData]) => {
  if (newAst && hasData) {
    await nextTick()
    // Since we know there is page data, clear any global error states from above
    await clearError()
  }
})

// If the preview page data changes, refresh the page content
watch(() => previewModeState.value.data.pages, async (previewPageData) => {
  // When the preview page data map changes, if preview mode is enabled and the current route path is in the map, clear any global error state
  if (previewModeEnabled.value && previewPageData?.has(route.path)) {
    await parseMarkdownContent()
  }
}, { deep: true, immediate: true })

onMounted(async () => {
  // Determine if the app is running inside an iframe (likely because of previewMode)
  const inIframe = typeof window !== 'undefined' && window.self !== window.top

  // If preview mode is enabled, wait the duration of the delay
  // before showing the 404 error in order to give the host app time to
  // send the preview data payload
  if (previewModeEnabled.value && shouldShowPreviewModeError.value) {
    // If in an iframe, wait 5 seconds before showing the 404 error
    await new Promise(resolve => setTimeout(resolve, inIframe ? 3000 : 0))

    await nextTick()

    if (!hasPreviewModePageData.value) {
      showError({
        statusCode: 404,
        statusMessage: t('errors.pages.not_found'),
      })
    }
  }
})

defineRouteRules({
  // Disable caching when preview mode is enabled
  cache: previewModeEnabled.value ? false : {
    swr: true,
    maxAge: 1800,
    staleMaxAge: 300,
    // !Important: `varies` is required to cache based on the host
    varies: ['host', 'x-forwarded-host', 'cookie', 'set-cookie'],
  },
  robots: pageData.value?.public || false,
})

useSeoMeta({
  title: () => pageData.value?.title || ast.value?.data?.title || '',
  description: () => ast.value?.data?.description,
  // Add for lastMod support
  articleModifiedTime: () => pageData.value?.updated_at,
})

useSchemaOrg([
  defineWebPage({
    datePublished: () => pageData.value?.created_at,
    dateModified: () => pageData.value?.updated_at,
  }),
])

/**
 * Check if the user customized the og:image via frontmatter, and use this image rather than the default from @nuxt/seo.
 *
 * Example:
  ---
  title: My Custom Page
  description: Check out all the cool things we offer!
  image:
    url: https://picsum.photos/id/237/600/400
    alt: Small black puppy
    width: 600
    height: 400
  ---
 */
if (ast.value?.data?.image) {
  const { src, url, alt, width, height } = ast.value.data.image

  // `url` is the correct property in the frontmatter; however, check
  // for `src` as well in case the user is using the wrong property
  const imageUrl = url || src

  if (imageUrl) {
    const w = Number(String(width || '').trim().replace('px', ''))
    const h = Number(String(height || '').trim().replace('px', ''))
    const imageWidth = isNaN(w) || Number(w) <= 0 ? undefined : w
    const imageHeight = isNaN(h) || Number(h) <= 0 ? undefined : h
    // Set the image URL, width, height, and alt text for the og:image meta tag
    defineOgImage({ url: imageUrl, width: imageWidth, height: imageHeight, alt })
  }
}
</script>
